import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'load',
  component: () => import('../views/load.vue')
},
{//首页
  path: '/home',
  name: 'home',
  component: () => import('../views/home.vue')
},
{//二级页
  path: '/ppr_list',
  name: 'ppr_list',
  component: () => import('@/views/ppr/list')
},
{//详情页
  path: '/details',
  name: 'details',
  component: () => import('@/views/ppr/details')
},
{//搜索页
  path: '/sew_list',
  name: 'sew_list',
  component: () => import('@/views/ppr/sew_list')
},
{//集团产业
  path: '/industry',
  name: 'industry',
  component: () => import('@/views/ppr/industry')
},
{//人员查询
  path: '/StaffQueries',
  name: 'StaffQueries',
  component: () => import('@/views/StaffQueries')
},
{//人员查询
  path: '/StaffQueriesNew',
  name: 'StaffQueriesNew',
  component: () => import('@/views/StaffQueriesNew')
},

{ path: '*', redirect: '/home', hidden: true }
]
const router = new VueRouter({
  mode: "history",
  routes
})

export default router